<template>
  <div>
    <img src="../assets/images/404.jpg" alt="">
  </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style lang="less" scoped>
div {
  text-align: center;
}
</style>